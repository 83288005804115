import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Navbar from '../../Nav_and_Footer/Navbar'
import Footer from '../../Nav_and_Footer/Footer'
import { Helmet } from "react-helmet";

function Case_std_single() {

    const navigate = useNavigate();
    const { caselink } = useParams()
    const [latestcase, setLatestpost] = useState("")

    const getSingle_blog_data = async (e) => {
        const res = await fetch("/single-case-data", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                caselink
            })
        });
        const data = await res.json();
        setLatestpost(data);
        if (res.status === 422 || !data) {
            window.alert("This blog is not found")
            navigate("/our-case-studies");
        }
        else {
            // window.alert("Login successfully")
        }
    }
    useEffect(() => {
        getSingle_blog_data();
        window.scroll({
            top: 0,
            behavior: "smooth"
        });

    }, []);

    return (
        <>
            <Navbar />
            <div className="single_blog_all_cont">
                <Helmet>
                    <title>{latestcase.case_title}</title>
                    <meta name="description" content={latestcase.case_discription} />

                    <meta property="og:title" content={latestcase.case_title} />
                    <meta property="og:description" content={latestcase.case_discription} />
                    <meta property="og:image" content={latestcase.case_thumbnail} />

                </Helmet>
                <div className="forcenter">
                    <div className="singleBLog">
                        <div dangerouslySetInnerHTML={{ __html: latestcase.case_cont }}></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Case_std_single