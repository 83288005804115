import React from 'react'
import Slider from './Slider';
import MediaQuery from 'react-responsive'

function Front_main_screen() {
    return (
        <>
            <div className="main_screen_all_cont">
                <MediaQuery maxWidth={768}><h2 className='msac_h2'>WE BELIEVE IN RESEARCH & RESULTS</h2></MediaQuery>

                <div className="h1_slider">
                
                    <div className="MSAC_first_box">
                        <div className="msac_h1">
                        <MediaQuery minWidth={768}><h2 className='msac_h2'>WE BELIEVE IN RESEARCH & RESULTS</h2></MediaQuery>
                            <h2 className='h2bigFont'>WANT TO BE #1 <br /> IN YOUR <span className='span1'>DOMAIN?</span> <br /> YOU'RE AT THE <div className='span2'>RIGHT PLACE!</div></h2>

                        </div>
                        <h1 className='msacP'>Weby Frog is a dynamic digital marketing agency that combines creativity, data driven strategies, and cutting-edge technology to propel businesses forward in the online landscape.</h1>
                        <div className='home_get_in_touch'><a className='home_get_in_toucha' target='blank' href="https://forms.gle/3VVDcGrWRpRDeEns5">Get in Touch</a></div>

                    </div>
                    <div className="MSAC_second_box">
                        <Slider />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Front_main_screen