import React, { useState, useEffect } from 'react'
import Navbar from '../Nav_and_Footer/Navbar'
import Footer from '../Nav_and_Footer/Footer'
import { Link } from 'react-router-dom'
import aeroplane from "../../resources/aeroplane.svg"
import Webdev from './Webdev'
import Seo from './Seo'
import AndroidDev from './AndroidDev'
import DandB from './DandB'
import Ads from './Ads'
import Ecommerce from './Ecommerce'
import Smm from './Smm'
import Aiautomation from './Aiautomation'
import TelegramBot from './TelegramBot'
import WhatsappAutomation from './WhatsappAutomation'

function OurServices_main(props) {

  const [activeLink, setActiveLink] = useState(parseInt(props.activelink))

  const allServices = [
    { id: 1, text: 'WEB DEVELOPMENT', link: "/our-services/web-development" },
    { id: 2, text: 'SEO', link: "/our-services/search-engine-optimization-seo" },
    { id: 3, text: 'APP DEVELOPMENT', link: "/our-services/android-development" },
    { id: 4, text: 'DESIGN AND BRANDING', link: "/our-services/design-and-branding" },
    { id: 5, text: 'ADS', link: "/our-services/ads" },
    { id: 6, text: 'E-COMMERCE', link: "/our-services/e-commerce" },
    { id: 7, text: 'SMM', link: "/our-services/social-media-marketing" },
    { id: 8, text: 'AI-AUTOMATION', link: "/our-services/ai-automation" },
    // { id: 9, text: 'TELEGRAM BOT AND WEB APP BUILDING', link: "/our-services/telegram-bot-and-web-app-building" },
    // { id: 10, text: 'WHATSAPP AUTOMATION AND BUSSINESS', link: "/our-services/whatsapp-automation-and-bussiness" }
  ];
  const linkactivated = (e) => {
    let innerHtlm = e.target.innerHTML;
    if (innerHtlm === "WEB DEVELOPMENT") {
      setActiveLink(1)
    } else if (innerHtlm === "SEO") {
      setActiveLink(2)
    } else if (innerHtlm === "APP DEVELOPMENT") {
      setActiveLink(3)
    } else if (innerHtlm === "DESIGN AND BRANDING") {
      setActiveLink(4)
    } else if (innerHtlm === "ADS") {
      setActiveLink(5)
    } else if (innerHtlm === "E-COMMERCE") {
      setActiveLink(6)
    } else if (innerHtlm === "SMM") {
      setActiveLink(7)
    } else if (innerHtlm === "AI-AUTOMATION") {
      setActiveLink(8)
    } else if (innerHtlm === "TELEGRAM BOT AND WEB APP BUILDING") {
      setActiveLink(9)
    } else if (innerHtlm === "WHATSAPP AUTOMATION AND BUSSINESS") {
      setActiveLink(10)
    }
  }

  const ServicesDisplay_data = () => {
    if (activeLink === 1) {
      return (
        <Webdev />
      )
    } else if (activeLink === 2) {
      return (
        <Seo />
      )
    } else if (activeLink === 3) {
      return (
        <AndroidDev />
      )
    }
    else if (activeLink === 4) {
      return (
        <DandB />
      )
    }
    else if (activeLink === 5) {
      return (
        <Ads />
      )
    }
    else if (activeLink === 6) {
      return (
        <Ecommerce />
      )
    }
    else if (activeLink === 7) {
      return (
        <Smm />
      )
    }
    else if (activeLink === 8) {
      return (
        <Aiautomation />
      )
    }
    else if (activeLink === 9) {
      return (
        <TelegramBot />
      )
    }
    else if (activeLink === 10) {
      return (
        <WhatsappAutomation />
      )
    }

  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])


  return (
    <>
      <Navbar />
      <div className="ourservices_all_cont">
        <h2 className='ourservicesh21'>OUR SERVICES <img src={aeroplane} alt="..." /></h2>
        <div className="all_services_link">
          <ul>

            {
              allServices.map((ele) => {
                return (
                  <>
                    <li key={ele.id}>
                      <Link onClick={linkactivated} className={activeLink === ele.id ? "sellected_link" : "nonselect"} to={ele.link}>{ele.text}</Link>
                    </li>
                  </>
                )
              })
            }

          </ul>
        </div>
        <ServicesDisplay_data />
      </div>
      <Footer />
    </>
  )
}

export default OurServices_main