import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../../Nav_and_Footer/Navbar'
import Footer from '../../Nav_and_Footer/Footer'
import aeroplane from "../../../resources/aeroplane.svg"
import blog_link_img from "../../../resources/bloglink_arow.svg"
import lefy_aeroplane from "../../../resources/leftplan.svg"
import { Helmet } from "react-helmet";

function All_case_stedies() {

    const [latestcase, setLatestcase] = useState([])
    const [pages, setPages] = useState([])
    const [currentpage, setCurrentpage] = useState(1)


    const totalPost_count = async () => {
        try {
            const res = await fetch("/total-casestd", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const data2 = await res.json();
            let intd = parseInt(data2)
            let setp = Math.ceil(intd / 6)
            setPages([...Array(setp)])

        } catch (err) {
            console.log(err)
        }
    }

    const getPosts = async (pagedo) => {
        let firstpage = pagedo;
        try {
            const res = await fetch("/get-casestd", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    firstpage
                })
            });
            const data = await res.json();
            if (res.status === 422 || !data) {
                window.alert("kuch galat huwa hai")
            }
            else {
                setLatestcase(data);
                window.scrollTo({ top: 0, behavior: "smooth" })
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    const btnClick = (e) => {
        setCurrentpage(e.target.innerHTML)
        getPosts(e.target.innerHTML)

    }

    useEffect(() => {
        totalPost_count();
        getPosts(1);
    }, []);

    return (
        <>
            <Navbar />
            <div className="allcse_cont">
                <Helmet>
                    <title>Our Case Studies</title>
                    <meta name="description" content="See the impact of our business solutions. Explore our case studies to learn how we've helped clients achieve remarkable results." />
                </Helmet>
                <h1>CASE STUDIES <img src={aeroplane} alt="..." /></h1>
                <h2>View Our Case Studies</h2>
                <div className="case_studies_boxes">
                    {
                        latestcase.map((ele) => {

                            return (

                                <div key={ele._id} className="case_studies_single_box">
                                    <img className='casestd_img' src={ele.case_thumbnail} alt={ele.img_alt} />
                                    <div className="blog_link">
                                        <Link to={"/our-case-studie/" + ele.case_link}><img src={blog_link_img} alt="..." /></Link>
                                    </div>
                                </div>

                            )

                        })
                    }

                </div>
                <div className="blog_pages">
                    <div className="left_right_plane">
                        <div className="planes">
                            <button><img src={lefy_aeroplane} alt="..." /></button>
                        </div>
                        {
                            pages.map((e, i) => {
                                i++
                                return (

                                    <div className='pages_BTN' key={i}>
                                        <button disabled={currentpage == i ? true : false} className={currentpage == i ? "btn_activate" : "page_buttons"} onClick={btnClick}>{i}</button>
                                    </div>
                                )
                            })
                        }

                        <div className="planes">
                            <button><img src={aeroplane} alt="..." /></button>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default All_case_stedies