import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { Pagination, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import MediaQuery from 'react-responsive'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Blogs_story() {

    const [latestpost, setLatestpost] = useState([])

    const getBlogs = async () => {
        try {
            const res = await fetch("/get-blogs", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const data = await res.json();
            setLatestpost(data);
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <>
            <MediaQuery minWidth={600}>
                <div className="blog2_allcontent">
                    <h2>BLOG</h2>
                    <h3>Read Our Blog Stories</h3>
                    <div className="all_bolg1">
                        {
                            latestpost.map((ele, i) => {
                                if (i < 3) {
                                    i++
                                    return (

                                        <div key={ele._id} className="blog1_single_box">
                                            <div className="blog1_img">
                                                <img src={ele.blog_thumbnail} alt={ele.img_alt} />
                                            </div>
                                            <div className="blog1_content">
                                                <div className="blog1_date_writer">
                                                    <div><span>09 April,2022</span></div>
                                                    <div><span>by Admin</span></div>
                                                </div>
                                                <p>{ele.blog_title.substring(0, 80)}<span>...</span></p>
                                                <span></span>
                                                <Link to={"/blog/" + ele.blog_link}>Read More</Link>
                                            </div>
                                        </div>

                                    )
                                }
                            })
                        }

                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={600}>
                <div className="Blog_story_m_v_all_cont">
                    <div className="bsmvac_heading">
                        <h2>Read Our Blog Stories </h2>
                    </div>

                    <div className="B_S_M_V_A_C_swiper">
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            grabCursor={true}
                            spaceBetween={15}
                            slidesPerView={2.4}
                            loop={true}
                        >
                            {
                                latestpost.map((ele, i) => {
                                    if (i < 4) {
                                        i++
                                        return (
                                            <SwiperSlide key={ele._id}>
                                                <div className="blog1_single_box">
                                                    <div className="blog1_img">
                                                        <img src={ele.blog_thumbnail} alt={ele.img_alt} />
                                                    </div>
                                                    <div className="blog1_content">
                                                        <div className="blog1_date_writer">
                                                            <div><span>09 April,2022</span></div>
                                                            <div><span>by Admin</span></div>
                                                        </div>
                                                        <p>{ele.blog_title.substring(0, 60)} <span>....</span></p>
                                                        <span></span>
                                                        <Link to={"/blog/" + ele.blog_link}>Read More</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                        )
                                    }
                                })}

                        </Swiper>

                    </div>

                </div>
            </MediaQuery>
        </>
    )
}

export default Blogs_story;