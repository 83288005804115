import React, { useEffect, useState } from 'react'
import blog_link_img from "../../resources/bloglink_arow.svg"
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive'
function Portfolio() {

    const [latestcase, setLatestcase] = useState([])
    const getBlogs = async () => {
        try {
            const res = await fetch("/get-casestd", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const data = await res.json();
            setLatestcase(data);
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getBlogs();
    }, []);

    return (
        <>
            <div className="portfolio_all_content_main">
                <div className="portfolio_all_content">
                    <div className="folio1_box">
                        <h3>PORTFOLIO</h3>
                        <h2>View Our Case Studies</h2>
                    </div>
                    <div className="folio2_box">
                        <Link to="/our-case-studies">View All <i class="fa-solid fa-arrow-right-long"></i></Link>
                        <p>Discover the transformative power of digital marketing with Weby Frong’s case studies. Dive into real-world success stories that showcase our expertise in driving growth and innovation.</p>

                    </div>
                </div>
                <div className="blogs_allcontent">


                    {
                        latestcase.map((ele) => {

                            return (

                                <div key={ele._id} className="blog_single_box">
                                    <img className='casestd_img' src={ele.case_thumbnail} alt={ele.img_alt} />
                                    <div className="blog_link">
                                        <Link to={"/our-case-studie/" + ele.case_link}><img src={blog_link_img} alt="..." /></Link>
                                    </div>
                                </div>

                            )

                        })
                    }

                </div>
                <MediaQuery maxWidth={600}>
                    <div className="folio1_box_for_m_view">
                        <h2>Our Case Studies</h2>
                    </div>
                </MediaQuery>
            </div>
        </>
    )
}

export default Portfolio