import React from 'react'
import MediaQuery from 'react-responsive'
function Book_meeting() {
  return (
    <>
      <div className="book_meeting_all_cont">
        <div className="bmac_black_box">
          <div className="bmac_hedming">
            <MediaQuery minWidth={600}>
              <h3 className='bmac_h3'>We Like To Start Your
                Project With Us</h3>
            </MediaQuery>
            <MediaQuery maxWidth={600}>
              <h3 className='bmac_h3'>We Like To <br /> Start Your
                Project With Us</h3>
            </MediaQuery>
            
          </div>
          <div className="bmac_book">
            <a target='blank' className='bmac_a' href="https://forms.gle/143pAYrNaDLN14WKA">Book a Meeting</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Book_meeting