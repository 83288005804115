import React from 'react'
import blackplane from "../../resources/blackplane.svg"
import Getstart from './Getstart'
import { Helmet } from "react-helmet";

function Seo() {
  return (
    <>
      <div className="activelink_all_cont">
        <Helmet>
          <title>SEO</title>
          <meta name="description" content="We help businesses unlock their full potential by dominating search engine rankings." />
        </Helmet>
        <h1>SEO <img src={blackplane} alt="..." /></h1>
        <h3 className='activelinkh31'>WebyFrog: Dominate Search with Our SEO Expertise</h3>
        <p>At WebyFrog we help businesses unlock their full potential by dominating search engine rankings.
          Our comprehensive SEO services are designed to boost your online visibility drive organic traffic
          and convert visitors into customers.</p>
        <h2 className='activelinkh21'>Our SEO Services</h2>
        <div>
          <ul>
            <li> <span>In-Depth Keyword Research: </span>
              We identify relevant keywords that align with your business goals
              and target audience to optimize your content and campaigns.
            </li>
            <li> <span>On-Page Optimization: </span>
              We enhance your website's on-page elementsl including title tagsl meta
              descriptionsl header tagsl and contentl to improve search engine visibility.
            </li>
            <li> <span> Technical SEO Audit:</span>
              We analyze your website's technical aspectsl such as site speedl mobilefriendlinessl and indexingl to identify and resolve issues that hinder performance.
            </li>
            <li> <span>Link Building: </span>
              We build high-quality backlinks to your website from authoritative sources to
              improve your domain authority and search rankings.
            </li>
            <li> <span>Local SEO:</span>
              We optimize your online presence for local searchl helping you attract customers in
              your geographic area.
            </li>
            <li><span>Content Marketing: </span>
              We create engaging and informative content that targets your ideal
              audience and improves your website's authority.
            </li>
            <li> <span>Performance Tracking and Reporting: </span>
              We monitor your website's performancel track key metricsl
              and provide regular reports to measure success.
            </li>
          </ul>
        </div>
        <h3 className='activelinikh32'>How We Help You ?</h3>
        <div>
          <ul>
            <li> <span>Increased Organic Traffic: </span>
              Boost your website's visibility and attract more potential customers.
            </li>
            <li><span>Higher Search Rankings: </span>
              Improve your search engine rankings for targeted keywords.
            </li>
            <li> <span>Improved User Experience: </span>
              Enhance website speed and mobile-friendliness for better user
              engagement.
            </li>
            <li> <span>Enhanced Brand Visibility: </span>
              Build brand awareness and credibility through targeted SEO
              strategies.
            </li>
            <li> <span>Increased Conversions:</span>
              Drive more leads and sales through optimized website content and
              structure.
            </li>
          </ul>
        </div>
        <p>At WebyFrogl we are committed to delivering measurable results and helping your business thrive
          in the competitive online landscape.</p>
      </div>
      <Getstart content="Contact us today to unlock the power of SEO for your business. " link="https://forms.gle/143pAYrNaDLN14WKA" />
    </>
  )
}

export default Seo