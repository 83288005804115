import React from 'react'

function Getstart(props) {
    return (
        <>
            <div className="getstartedAllocnt">
                <div className='getStartp'>
                    <p>
                        {props.content}
                    </p>
                </div>
                <a target='blank' href={props.link}>GET START</a>
            </div>
        </>
    )
}

export default Getstart