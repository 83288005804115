import React from 'react'
import blackplane from "../../resources/blackplane.svg"
import Getstart from './Getstart'
import { Helmet } from "react-helmet";

function Smm() {
  return (
    <>
      <div className="activelink_all_cont">
        <Helmet>
          <title>Social Media Marketing</title>
          <meta name="description" content="In today's digital age, where attention spans are fleeting and competition is fierce, a strong social media presence is no longer a luxury but a necessity." />
        </Helmet>
        <h1>Social Media Marketing<img src={blackplane} alt="..." /></h1>
        <p>In today's digital age, where attention spans are fleeting and competition is fierce, a strong social media presence is no longer a luxury but a necessity. At WebyFrog, we understand the transformative power of social media and how it can catapult your brand to new heights. We are your dedicated partners in harnessing the full potential of these platforms to connect with your audience, drive engagement, and ultimately, grow your business.</p>
        <h2 className='activelinkh21'>Our Mission : </h2>
        <p>Our mission is to empower brands to thrive in the dynamic world of social media. We achieve this by crafting tailored strategies that resonate with your target audience, creating compelling content that sparks conversations, and leveraging data-driven insights to optimize your performance. Our goal is to not just build a social media presence, but to cultivate a loyal community of brand advocates who become your most powerful marketing asset.</p>
        <h3 className='activelinikh32'>Our Services</h3>
        <div>
          <ul>
            <li> <span>In-Depth Social Media Audit: </span>
              We begin by conducting a comprehensive analysis of your current social media landscape. This includes evaluating your existing content, audience demographics, competitor analysis, and platform performance. The insights gained from this audit form the foundation for a robust social media strategy.
            </li>
            <li> <span>Strategic Planning: </span>

              Based on our audit findings and your business objectives, we develop a customized social media strategy that aligns with your brand identity and resonates with your target audience. Our strategic planning process includes defining clear goals, identifying key performance indicators (KPIs), and selecting the most suitable social media platforms for your brand.
            </li>

            <li> <span>Content Creation: </span>
              Engaging content is the lifeblood of any successful social media campaign. Our team of creative experts specializes in crafting compelling content that captures attention, informs, entertains, and inspires. Whether it's captivating visuals, informative blog posts, engaging videos, or interactive content, we create content that drives engagement and builds brand awareness.
            </li>

            <li> <span>Community Management: </span>
              Building a strong community around your brand is essential for long-term success. Our community management services involve actively engaging with your audience, responding to comments and messages promptly, and fostering meaningful conversations. We build relationships with your followers, turning them into loyal brand advocates.
            </li>
            <li> <span>Social Media Advertising: </span>
              To accelerate growth and reach a wider audience, we leverage the power of social media advertising. Our experts create targeted ad campaigns that deliver maximum ROI by reaching the right people with the right message at the right time. We continuously optimize ad performance to ensure optimal results.

            </li>

          </ul>
        </div>
        <p>We believe in the power of data-driven decision making. Our team utilizes advanced analytics tools to track key performance indicators, measure campaign effectiveness, and identify opportunities for improvement. We continuously optimize your social media strategy based on data insights to ensure maximum ROI.</p>
        <h3 className='activelinikh32'>Why Choose WebyFrog?</h3>
        <div>
          <ul>
            <li> <span>Expertise: </span>
              Our team comprises seasoned social media experts with a deep understanding of the ever-evolving social media landscape.
            </li>
            <li><span>Customization: </span>
              We tailor our services to meet the unique needs and goals of each client.
            </li>
            <li> <span>Data-Driven Approach: </span>
              We rely on data and analytics to optimize our strategies for maximum impact.
            </li>
            <li> <span>Results-Oriented: </span>
              Our focus is on delivering measurable results that drive business growth.
            </li>
            <li> <span>Passion: </span>
              We are passionate about social media and its potential to transform brands
            </li>
          </ul>
        </div>
        <p>By partnering with WebyFrog, you gain a dedicated team of experts who are committed to helping you achieve your social media goals. We are not just a service provider; we are your partners in growth. Let us help you unlock the power of social media and elevate your brand to new heights.</p>
      </div>
      <Getstart content="Contact us today to schedule a consultation and discover how WebyFrog can help your brand thrive on social media." link="https://forms.gle/143pAYrNaDLN14WKA" />
    </>
  )
}

export default Smm