import React from 'react'
import blackplane from "../../resources/blackplane.svg"
import Getstart from './Getstart'
import { Helmet } from "react-helmet";

function Ads() {
  return (
    <>
      <div className="activelink_all_cont">
        <Helmet>
          <title>Ads</title>
          <meta name="description" content="We specialize in crafting high-performance advertising campaigns that drive tangible results
          for your business"/>
        </Helmet>
        <h1>ADS<img src={blackplane} alt="..." /></h1>
        <h3 className='activelinkh31'>WebyFrog: Your Path to Digital Dominance</h3>
        <p>At WebyFrog, we specialize in crafting high-performance advertising campaigns that drive tangible results
          for your business. Our deep understanding of Meta Ads and Google Ads platforms, combined with datadriven strategies, enables us to optimize your ad spend and maximize your return on investment (ROI).</p>
        <h2 className='activelinkh21'>Meta Ads Mastery <img src={blackplane} alt=".." /></h2>
        <p>Meta Ads, encompassing platforms like Facebook, Instagram, and WhatsApp, offer unparalleled
          opportunities to connect with your target audience. Our Meta Ads experts excel in.</p>
        <div>
          <ul>
            <li><span> Custom App Development: </span>
              In-depth Audience Targeting: We precisely define your ideal customer, reaching them with laserfocused ad campaigns across Meta's oast network.
            </li>
            <li>
              <span>iOS and Android App Development: </span>
              Compelling Ad Creative: Our creative team designs oisually stunning and engaging ads that capture
              attention and drive conversions.
            </li>
            <li>
              <span>Cross-Platform App Development: </span>
              Performance Optimization: We continuously monitor and optimize your campaigns, leveraging
              advanced analytics to refine targeting, bidding, and ad copy.
            </li>
            <li>
              <span>UI/UX Design: </span>
              We prioritize user experience by creating intuitive and visually stunning Brand Awareness and Engagement: Beyond conversions, we build strong brand recognition and foster
              loyal customer relationships.
            </li>
          </ul>
        </div>


        <h2 className='activelinkh21'>Google Ads Expertise <img src={blackplane} alt=".." /></h2>
        <p>Google Ads is a powerhouse for driving traffic and leads. Our Google Ads specialists deliver exceptional
          results through.</p>
        <div>
          <ul>
            <li><span> Custom App Development: </span>
              Keyword Optimization: We identify high-performing eeywords to ensure your ads appear prominently in
              search results.
            </li>
            <li>
              <span>iOS and Android App Development: </span>
              Search and Display Campaigns: We create tailored campaigns for both search and display networks,
              maximizing your reach.
            </li>
            <li>
              <span>Cross-Platform App Development: </span>
              Conversion Tracking and Optimization: We meticulously track conversions to measure campaign
              effectiveness and refine strategies.
            </li>
            <li>
              <span>Cross-Platform App Development: </span>
              Local SEO Integration: We seamlessly integrate Google Ads with local SEO for enhanced oisibility in local
              search results.
            </li>
          </ul>
        </div>

        <h3 className='activelinikh32'>Why Choose WebyFrog ?</h3>
        <div>
          <ul>
            <li>
              <span>Turn Ideas into Reality: </span>
              Data-Driven Approach: We rely on robust analytics to inform our strategies and measure performance.
            </li>
            <li> <span>Enhance User Experience: </span>
              Proven Results: Our track record speaks for itself, with numerous clients achieving exceptional ROI.

            </li>
            <li> <span>Accelerate Time-to-Market: </span>
              Customized Solutions: We tailor our services to meet your unique business goals and objectives.
            </li>
            <li>
              <span>Maximize App Performance: </span>
              Stay Ahead of Trends: Our team stays up-to-date with the latest platform updates and advertising
              trends.
            </li>
          </ul>
        </div>
        <p>By partnering with WebyFrog, you gain a dedicated team of experts committed to your success. Let us help
          you unlock the full potential of Meta Ads and Google Ads to achieve your business objectives.</p>
      </div>
      <Getstart content="Contact us today to schedule a consultation and discover how WebyFrog can drive your business forward." link="https://forms.gle/143pAYrNaDLN14WKA" />
    </>
  )
}

export default Ads