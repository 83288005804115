import React, { useEffect, useState } from 'react'
import Navbar from '../../Nav_and_Footer/Navbar'
import Footer from '../../Nav_and_Footer/Footer'
import aeroplane from "../../../resources/aeroplane.svg"
import lefy_aeroplane from "../../../resources/leftplan.svg"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function All_Blogs() {

    const [latestpost, setLatestpost] = useState([])
    const [pages, setPages] = useState([])
    const [currentpage, setCurrentpage] = useState(1)


    const totalPost_count = async () => {
        try {
            const res = await fetch("/total-blogs", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const data2 = await res.json();
            let intd = parseInt(data2)
            let setp = Math.ceil(intd / 9)
            setPages([...Array(setp)])

        } catch (err) {
            console.log(err)
        }
    }

    const getPosts = async (pagedo) => {
        let firstpage = pagedo;
        try {
            const res = await fetch("/get-blogs", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    firstpage
                })
            });
            const data = await res.json();
            if (res.status === 422 || !data) {
                window.alert("Something Wrong!")
            }
            else {
                setLatestpost(data);
                window.scrollTo({ top: 0, behavior: "smooth" })
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    const btnClick = (e) => {
        setCurrentpage(e.target.innerHTML)
        getPosts(e.target.innerHTML)
        
    }

    useEffect(() => {
        totalPost_count();
        getPosts(1);
    }, []);

    return (
        <>
            <Navbar />
            <Helmet>
                <title>Blogs</title>
                <meta name="description" content="Stay ahead of the curve with our latest marketing solutions insights. Discover actionable tips, trends, and strategies to boost your online presence." />
            </Helmet>
            <div className="all_blogs_all_cont">
                    <h1>BLOG <img src={aeroplane} alt="..." /></h1>
                    <div className="all_blog2_allcontent">
                        <h3>Read Our Blog Stories</h3>
                        <div className="all_all_bolg">
                            {
                                latestpost.map((ele) => {
                                    return (
                                        <div key={ele._id} className="all_blog1_single_box2">
                                            <div className="all_blog1_img">
                                                <img src={ele.blog_thumbnail} alt={ele.img_alt} />
                                            </div>
                                            <div className="all_blog1_content">
                                                <div className="all_blog1_date_writer">
                                                    <div><span>{new Date(ele.date).toDateString()}</span></div>
                                                    <div><span>by Admin</span></div>
                                                </div>
                                                <p>{ele.blog_title.substring(0, 80)}<span>...</span></p>
                                                <Link to={"/blog/" + ele.blog_link}>Read More</Link>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="blog_pages">
                            <div className="left_right_plane">
                                <div className="planes">
                                    <button><img src={lefy_aeroplane} alt="..." /></button>
                                </div>
                                {
                                    pages.map((e, i) => {
                                        i++
                                        return (

                                            <div className='pages_BTN' key={i}>
                                                <button disabled={currentpage == i ? true : false} className={currentpage == i ? "btn_activate" : "page_buttons"} onClick={btnClick}>{i}</button>
                                            </div>
                                        )
                                    })
                                }

                                <div className="planes">
                                    <button><img src={aeroplane} alt="..." /></button>

                                </div>
                            </div>
                        </div>
                    </div>
                
            </div>
            <Footer />
        </>
    )
}

export default All_Blogs