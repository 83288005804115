import React from 'react'
import MediaQuery from 'react-responsive'
import our_exp1 from "../../resources/our_experties/design.svg"
import our_exp2 from "../../resources/our_experties/webdev.svg"
import our_exp3 from "../../resources/our_experties/android.svg"
import our_exp4 from "../../resources/our_experties/seo.svg"
import our_exp5 from "../../resources/our_experties/google_ads.svg"
import our_exp6 from "../../resources/our_experties/shopifi.svg"
import { Link } from 'react-router-dom'

function OurExperties() {
    return (
        <>
            <div className="ourexperties_allcont">
                <h3 className='exh3'>Weby-Frog Feild OF Experties</h3>
                <div className="all_experties">
                    <Link to="/our-services/design-and-branding" className="eperties_single_box">
                        <div className="exp_logo">
                            <img src={our_exp1} alt="webyfrog is expert in design and brand identity" />
                        </div>
                        <div className="exp_para">
                            <h2 className='exp_h2'>Design & Brand Identity</h2>
                            <p className='exp_p'>At Weby Frong, we understand that your
                                brand is the cornerstone of your business
                                identity. Our bespoke design and branding
                                services are crafted to set your brand
                                apart.</p>
                        </div>
                    </Link>
                    <Link to="/our-services/web-development" className="eperties_single_box">
                        <div className="exp_logo">
                            <img src={our_exp2} alt="webyfrog is expert in web development" />
                        </div>
                        <div className="exp_para">
                            <h2 className='exp_h2'>Web Development</h2>
                            <p className='exp_p'>Our team specializes in creating cuttingedge websites that blend aesthetic appeal with functional design. From ecommerce solutions to custom web applications</p>
                        </div>
                    </Link>
                    <Link to="/our-services/android-development" className="eperties_single_box">
                        <div className="exp_logo">
                            <img src={our_exp3} alt="webyfrog is expert in android development" />
                        </div>
                        <div className="exp_para">
                            <h2 className='exp_h2'>Android Development</h2>
                            <p className='exp_p'>Applications deliver seamless user experiences. Our team harnesses the power of the latest Android technologies to build innovative, scalable, and performance-driven apps.</p>
                        </div>
                    </Link>
                    <Link to="/our-services/search-engine-optimization-seo" className="eperties_single_box">
                        <div className="exp_logo">
                            <img src={our_exp4} alt="webyfrog is expert in search engine optimization (SEO)" />
                        </div>
                        <div className="exp_para">
                            <MediaQuery minWidth={600}>
                                <h2 className='exp_h2'>SEO</h2>
                            </MediaQuery>
                            <p className='exp_p'>At Weby Frong, we’re dedicated to propelling your brand to the top of search engine rankings.</p>
                            <MediaQuery maxWidth={600}>
                                <h2 className='exp_h2'>Search Engine Optimization</h2>
                            </MediaQuery>
                        </div>
                    </Link>
                    <Link to="/our-services/ads" className="eperties_single_box">
                        <div className="exp_logo">
                            <img src={our_exp5} alt="webyfrog is expert in google ads and facebook ads" />
                        </div>
                        <div className="exp_para">
                            <h2 className='exp_h2'>Google ADs & FB ADs</h2>
                            <p className='exp_p'>Our targeted advertising strategies are designed to capture your audience’s attention at the right moment</p>
                        </div>
                    </Link>
                    <Link to="/our-services/e-commerce" className="eperties_single_box">
                        <div className="exp_logo">
                            <img src={our_exp6} alt="webyfrog is expert in shopify and woo-commerce" />
                        </div>
                        <div className="exp_para">
                            <MediaQuery minWidth={600}>
                                <h2 className='exp_h2'>Shopify & Woocommerce</h2>
                            </MediaQuery>
                            <MediaQuery maxWidth={600}>
                                <h2 className='exp_h2'>Shopify & Woo commerce</h2>
                            </MediaQuery>

                            <p className='exp_p'>Weby Frong is your go-to partner for exceptional Shopify & WooCommerce services</p>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default OurExperties