import React from 'react'
import footerLogo from "../../resources/weby_footer_logo.svg"
import { Link } from 'react-router-dom'

function Aboutus() {
    return (
        <>
            <div className="aboutAllContent">
                <div className="img_box">
                </div>
                <div className="content_box">
                    <h3>ABOUT WEBY FROG</h3>
                    <h2><img src={footerLogo} alt="webyfrog digital marketing company" />: Crafting Digital Excellence.</h2>
                    <p>At Weby Frog, we're not just a digital marketing agency we're architects of the digital world. With a passion for innovation and a flair for creativity, we offer a suite of services that encompass web development, Android app creation, SEO, Google Ads, and Facebook Ads. Our commitment to excellence is evident in every project we undertake, ensuring that our clients not only meet but exceed their digital marketing goals. We pride ourselves on delivering quality design and branding solutions that tell your story, engage your audience, and build your brand</p>
                    <Link className='aboutus_link' to="/about-us">Learn More</Link>
                </div>

            </div>
        </>
    )
}

export default Aboutus