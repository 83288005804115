import { React, useRef } from 'react'
import { Link } from "react-router-dom";
import webyfrog from "../../resources/webyFrog_logo.svg"
import hamburger from "../../resources/hamburgur.svg"

function Navbar() {

    const navRef = useRef();
    const showNavbar = () => {
        navRef.current.classList.toggle(
            "responsive_nav"
        );
    };

    const autoscroll = () => {
        setTimeout(function () {
            const scrolling = () => {
                const element = document.getElementById('our_work_id');
                element?.scrollIntoView({
                    behavior: 'smooth'
                });
            }
            scrolling()
        }, 500);
    }

    return (
        <>
            <header>
                <Link to="/"><img className='webyfrog_logo' src={webyfrog} alt="webyfrog" /></Link>
                <div>
                    <nav ref={navRef}>
                        <Link to="/">Home</Link>
                        <Link onClick={autoscroll} to="/">Our Clients</Link>
                        <Link to="/our-services/web-development">Our Services</Link>
                        <Link to="/blogs">Blog</Link>
                        <a target='blank' href="https://forms.gle/143pAYrNaDLN14WKA">Contact</a>
                        <Link to="/about-us">About Us</Link>
                        <button
                            className="nav-btn nav-close-btn"
                            onClick={showNavbar}>

                            <div className="closebtn"><i class="fa-solid fa-xmark"></i></div>

                        </button>
                    </nav>
                    <button
                        className="nav-btn"
                        onClick={showNavbar}>
                        <img src={hamburger} alt="...." />
                    </button>
                </div>
            </header>


        </>
    )
}

export default Navbar