import './App.css';
import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import FrontPage_all_content from './components/FrontPage_all_content';
import Upload_blogs from './components/calling_backend/Upload_blogs';
import All_case_stedies from './components/Front_page_component/ourcaseStedis_all_pages/All_case_stedies';
import All_Blogs from './components/Front_page_component/all_Blogs/All_Blogs';
import Single_blog from './components/Front_page_component/all_Blogs/Single_blog';
import Aboutus from './components/aboutUs/Aboutus';
import OurServices_main from './components/our_services/OurServices_main';
import Upload_case from './components/calling_backend/Upload_case';
import Case_std_single from './components/Front_page_component/ourcaseStedis_all_pages/Case_std_single';

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<FrontPage_all_content />} />
                    <Route exact path="/our-case-studies" element={<All_case_stedies/>} />
                    <Route exact path="/blog/:blogurltit" element={<Single_blog/>} />
                    <Route exact path="/our-services/web-development" element={<OurServices_main activelink="1"/>} />

                    <Route exact path="/our-services/search-engine-optimization-seo" element={<OurServices_main activelink="2"/>} />

                    <Route exact path="/our-services/android-development" element={<OurServices_main activelink="3"/>} />

                    <Route exact path="/our-services/design-and-branding" element={<OurServices_main activelink="4"/>} />

                    <Route exact path="/our-services/ads" element={<OurServices_main activelink="5"/>} />

                    <Route exact path="/our-services/e-commerce" element={<OurServices_main activelink="6"/>} />

                    <Route exact path="/our-services/social-media-marketing" element={<OurServices_main activelink="7"/>} />

                    <Route exact path="/our-services/ai-automation" element={<OurServices_main activelink="8"/>} />

                    <Route exact path="/our-services/telegram-bot-and-web-app-building" element={<OurServices_main activelink="9"/>} />
                    <Route exact path="/our-services/whatsapp-automation-and-bussiness" element={<OurServices_main activelink="10"/>} />


                    <Route exact path="/about-us" element={<Aboutus/>} />
                    <Route exact path="/blogs" element={<All_Blogs/>} />
                    <Route exact path="/blogs/update" element={<Upload_blogs/>} />
                    <Route exact path="/our-case-studies" element={<All_case_stedies/>} />
                    <Route exact path="/our-case-studie/:caselink" element={<Case_std_single/>} />
                    <Route exact path="/our-case-studies/update" element={<Upload_case/>} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App