import React from 'react'
import blackplane from "../../resources/blackplane.svg"
import Getstart from './Getstart'
import { Helmet } from "react-helmet";

function AndroidDev() {
  return (
    <>
      <div className="activelink_all_cont">
        <Helmet>
          <title>App Development</title>
          <meta name="description" content="We build tailored applications that perfectly align with your business" />
        </Helmet>
        <h1>App Development<img src={blackplane} alt="..." /></h1>
        <h3 className='activelinkh31'>WebyFrog: Your App Development Partner</h3>
        <p>At WebyFrog, we transform innovative ideas into exceptional mobile and web applications. Our
          team of skilled developers and designers is dedicated to crafting user-centric solutions that
          drive business growth. We specialize in creating robust, scalable, and visually appealing apps that
          exceed your expectations.</p>
        <h2 className='activelinkh21'>Our App Development Service</h2>
        <div>
          <ul>
            <li><span> Custom App Development: </span>
              We build tailored applications that perfectly align with your business
              goals and target audience. From concept to launch, we handle every aspect of the
              development process.
            </li>
            <li>
              <span>iOS and Android App Development: </span>
              Our expertise spans both iOS and Android platforms,
              ensuring maximum reach and user engagement.
            </li>
            <li>
              <span>Cross-Platform App Development: </span>
              We leverage cutting-edge technologies to create apps
              that work seamlessly across multiple platforms, saving you time and resources.
            </li>
            <li>
              <span>UI/UX Design: </span>
              We prioritize user experience by creating intuitive and visually stunning app
              interfaces that keep users engaged.
            </li>
            <li>
              <span>App Testing and Quality Assurance: </span>
              Rigorous testing ensures that your app functions flawlessly
              and delivers a seamless user experience.
            </li>
            <li>
              <span>App store Optimization (AXO): </span>  We optimize your app's visibility and discoverability on app
              stores through strategic ASO techniques.
            </li>
            <li> <span>App Maintenance and support: </span>
              We provide ongoing support and updates to keep your app
              running smoothly and up-to-date.
            </li>
          </ul>
        </div>
        <h3 className='activelinikh32'>How We Help You ?</h3>
        <div>
          <ul>
            <li>
              <span>Turn Ideas into Reality: </span>
              Bring your app concept to life with our expert development team.
            </li>
            <li> <span>Enhance User Experience: </span>
              Create engaging and user-friendly apps that drive customer
              satisfaction.
            </li>
            <li> <span>Accelerate Time-to-Market: </span>
              Streamline the development process to launch your app faster.
            </li>
            <li>
              <span>Maximize App Performance: </span>
              Build high-performing apps that deliver exceptional user
              experiences.
            </li>
            <li> <span>Achieve Business Soals: </span>
              Develop apps that align with your business objectives and drive
              growth.
            </li>
          </ul>
        </div>
        <p>At WebyFrog, we are passionate about creating exceptional app experiences. Our commitment to
          quality, innovation, and customer satisfaction sets us apart.</p>
      </div>
      <Getstart content="Contact us today to discuss your app development project" link="https://forms.gle/143pAYrNaDLN14WKA" />
    </>
  )
}

export default AndroidDev