import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Navbar from '../../Nav_and_Footer/Navbar'
import Footer from '../../Nav_and_Footer/Footer'
import { Helmet } from "react-helmet";

function Single_blog() {
  const navigate = useNavigate();
  const { blogurltit } = useParams()
  const [latestpost, setLatestpost] = useState("")

  const getSingle_blog_data = async (e) => {
    const res = await fetch("/single-blog-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        blogurltit
      })
    });
    const data = await res.json();
    setLatestpost(data);
    if (res.status === 422 || !data) {
      window.alert("This blog is not found")
      navigate("/blogs");
    }
    else {
      // window.alert("Login successfully")
    }
  }
  useEffect(() => {
    getSingle_blog_data();
    window.scroll({
      top: 0,
      behavior: "smooth"
    });

  }, []);

  return (
    <>
      <Navbar />
      <div className="single_blog_all_cont">
        <Helmet>
          <title>{latestpost.blog_title}</title>
          <meta name="description" content={latestpost.blog_discription} />
        </Helmet>
        <div className="forcenter">
          <div className="singleBLog">
            <div dangerouslySetInnerHTML={{ __html: latestpost.blog_cont }}></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Single_blog