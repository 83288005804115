import React from 'react'
import Navbar from './Nav_and_Footer/Navbar'
import Front_main_screen from './Front_page_component/Front_main_screen'
import OurExperties from './Front_page_component/OurExperties'
import Our_work from './Front_page_component/Our_work'
import Footer from './Nav_and_Footer/Footer'
import Blogs_story from './Front_page_component/Blogs_story'
import Portfolio from './Front_page_component/Portfolio'
import Aboutus from './Front_page_component/Aboutus'
import Book_meeting from './Front_page_component/Book_meeting'
import M_view_about_us from './Front_page_component/M_view_about_us'
import { Helmet } from "react-helmet";


function FrontPage_all_content() {
  return (
    <>
      <div>
        <Helmet>
          <title>Webyfrog</title>
          <meta name="description" content="Weby Frog is a best digital marketing agency and company in sitapur, that combines creativity, data driven strategies, and cutting-edge technology to propel businesses forward in the online landscape." />
          <meta name="keywords" content="digital, marketing, agency, company, sitapur"/>
        </Helmet>

        <Navbar />
        <Front_main_screen />
        <OurExperties />
        <Our_work />
        <Aboutus />
        <Portfolio />
        <Blogs_story />
        <Book_meeting />
        <M_view_about_us />
        <Footer />
      </div>
    </>
  )
}

export default FrontPage_all_content