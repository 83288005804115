import React, { useState } from 'react'
import mainlogo from "../../resources/weby_footer_logo.svg"
import MediaQuery from 'react-responsive'
import black_plane from "../../resources/bsub_aeroplae_black.png"

function Footer() {
    const [mail, setmail] = useState("")

    const subscribing = async (e) => {
        e.preventDefault();
        const res = await fetch("/collet-email", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                mail
            })
        });
        const data = await res.json();
        setmail(data);
        if (res.status === 422 || !data) {
            window.alert("Please Enter The Mail")
        } else if (res.status === 409) {
            window.alert("You are already subscribed")
            setmail("")
        }
        else {
            window.alert("Subscribed")
            setmail("")
        }
    }
    const Contact = () => {
        return (
            <>
                <h2>Our Contact</h2>
                <h3>Sitapur, Uttar-Pradesh, India</h3>
                <h3>Help: +91 9453404589, +91 8318639989</h3>
                <h3>Email: connect@webyfrog.com</h3>
            </>
        )
    }
    const SubscribeCont = () => {
        return (
            <>

                <h2>Follow Us</h2>
                <div className="icons">
                    <a target='blank' href="https://www.instagram.com/webyfrog">

                        <i class="fa-brands fa-square-facebook"></i>
                    </a>

                    <a target='blank' href="https://x.com/webyfrog">
                        <i class="fa-brands fa-square-twitter"> </i>
                    </a>

                    <a target='blank' href="https://www.instagram.com/webyfrog">
                        <i class="fa-brands fa-instagram"></i>
                    </a>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="footer_main_all_box">
                <div className="footer_for_mess">
                    <MediaQuery minWidth={600}>
                        <div className="footer_all_content">
                            <div className="footerlogoAndConta">
                                <div className="footer_boxe1">
                                    <img className='mainlogo' src={mainlogo} alt="webyfrog" />
                                </div>
                                <div className="footer_boxe2">
                                    <Contact />
                                </div>
                            </div>
                            <div className="footer_boxe3">
                                <h2>About Us</h2>
                                <p>We are serving globally with a passion for innovation and a commitment to excellence, we provide tailored digital solutions to businesses worldwide. <br />
                                    “Welcome to our global family!”
                                </p>
                            </div>
                            <div className="footer_boxe4">
                                <div className="subscribe">
                                    <h3>Subscribe</h3>
                                    <form action="POST" className='footer_input'>
                                        <input value={mail} onChange={(e) => setmail(e.target.value)} placeholder='Your Email' type="email" />
                                        <button onClick={subscribing} type='submit'><img src={black_plane} alt=".." />
                                        </button>
                                    </form>
                                </div>
                                <SubscribeCont />
                            </div>
                        </div>
                    </MediaQuery>
                    <MediaQuery maxWidth={600}>
                        <div className="footer_m_view_all_cont">
                            <div className="footer_m_view_all_cont_first_box">
                                <Contact />
                            </div>
                            <div className="footer_m_view_all_cont_sec_box">
                                <div className="subscribe">
                                    <h3>Subscribe</h3>
                                    <form action="POST" className='footer_input'>
                                        <input value={mail} onChange={(e) => setmail(e.target.value)} placeholder='Your Email' type="email" />
                                        <button onClick={subscribing} type='submit'><img src={black_plane} alt=".." />
                                        </button>
                                    </form>
                                </div>
                                <SubscribeCont />
                            </div>
                        </div>
                    </MediaQuery>
                </div>
                <MediaQuery maxWidth={600}>
                    <div className="footer_message">
                        <p>OM NAMAH SHIVAY</p>
                    </div>
                </MediaQuery>

            </div>

        </>
    )
}

export default Footer