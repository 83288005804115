import React from 'react'
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive'


function M_view_about_us() {
    return (
        <>
            <MediaQuery maxWidth={600}>
                <div className="M_about_all_cont">
                    <h3>ABOUT US </h3>
                    <h2>Weby Frog: Crafting Digital Excellence.</h2>
                    <p>At Weby Frong, we’re not just a digital marketing agency, we’re
                        architects of the digital world. With a passion for innovation
                        and a flair for creativity, we offer a suite of services that
                        encompass web development, Android app
                        creation, SEO, Google Ads, and Facebook Ads. Our commitment
                        to excellence is evident in every project we undertake, ensuring
                        that our clients not only meet but exceed their digital
                        marketing goals. We pride ourselves on delivering quality
                        design and branding solutions that tell your story, engage your
                        audience, and build your brand.</p>

                        <Link to="/about-us">Learn More</Link>
                </div>

            </MediaQuery>
        </>
    )
}

export default M_view_about_us