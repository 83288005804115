import React from 'react'
import blackplane from "../../resources/blackplane.svg"
import Getstart from './Getstart'
import { Helmet } from "react-helmet";

function Webdev() {
  return (
    <>
      <div className="activelink_all_cont">
      <Helmet>
          <title>Web Development</title>
          <meta name="description" content="We build exceptional websites that not only look stunning but also deliver exceptional
          user experiences and drive business growth" />
        </Helmet>
        <h1>WEB DEVELOPMENT <img src={blackplane} alt="..." /></h1>
        <h3 className='activelinkh31'>WebyFrog: Crafting Digital Experiences</h3>
        <p>At WebyFrog, we build exceptional websites that not only look stunning but also deliver exceptional
          user experiences and drive business growth. Our team of skilled web developers and designers
          combines creativity with technical expertise to create websites that leave a lasting impression.</p>
        <h2 className='activelinkh21'>Our Web Development Services</h2>
        <div>
          <ul>
            <li><span>Custom Website Development: </span>
              We create tailored websites that perfectly align with your brand
              identity and business obuectives. From simple static sites to complex web applications, we
              deliver solutions that exceed expectations.
            </li>
            <li><span>E-commerce Development: </span>
              We build robust online stores that offer seamless shopping
              experiences, secure payment gateways, and efficient inventory management.
            </li>
            <li> <span>Responsive Web Design: </span>
               We create websites that look and function flawlessly across all
              devices, ensuring optimal user experience on desktops, tablets, and smartphones.
            </li>
            <li> <span>Web Application Development: </span>
               We develop custom web applications that streamline business
              processes, enhance productivity, and drive efficiency.
            </li>
            <li> <span>Website Maintenance and Support: </span>
               We provide ongoing website maintenance, updates, and
              support to keep your online presence running smoothly.
            </li>
            <li> <span>CMS Development: </span>
               We build custom content management systems (CMS) or implement
              popular platforms like WordPress, Drupal, or Joomla to empower you to manage your website
              content easily.
            </li>
          </ul>
        </div>
        <h3 className='activelinikh32'>How We Help You ?</h3>
        <div>
          <ul>
            <li> <span>Enhanced Online Presence: </span>
               Create a strong online presence that attracts and engages your
              target audience.
            </li>
            <li> <span>Improved User Experience: </span>
               Design intuitive and user-friendly websites that keep visitors
              engaged.
            </li>
            <li> <span>Increased Conversions: </span>
               Optimize your website for conversions, whether it's generating leads,
              sales, or sign-ups.
            </li>
            <li> <span>Boosted SEO: </span>
               Build websites with SEO best practices in mind to improve search engine rankings.
            </li>
            <li> <span>Scalability: </span>
               Create websites that can grow with your business, accommodating future
              expansion.
            </li>
          </ul>
        </div>
        <p>At WebyFrog, we are passionate about crafting digital experiences that drive results. Our
          commitment to quality, innovation, and client satisfaction sets us apart.</p>
      </div>
      <Getstart content="Contact us today to discuss your web development project." link="https://forms.gle/143pAYrNaDLN14WKA"/>
    </>
  )
}

export default Webdev