import React from 'react'

import { Autoplay, Pagination, EffectCoverflow } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import slider_img_one from "../../resources/final/1.jpg"
import slider_two from "../../resources/final/2.jpg"
import slider_img_three from "../../resources/final/3.jpg"
import slider_img_foure from "../../resources/final/4.jpg"



function Slider() {

    const device_size = useMediaQuery({
        query: '(min-width: 600px)'
      })
    
      const SWiper = ()=>{
        if (device_size) {
            return (
                <Swiper
                modules={[ Pagination, Autoplay]}
                grabCursor={true}
                spaceBetween={15}
                slidesPerView={1.4}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
            >
                <SwiperSlide><img className='slider_imgs' src={slider_img_one} alt="be number 1 on google search with webyfrog" /></SwiperSlide>
                <SwiperSlide><img className='slider_imgs' src={slider_img_foure} alt="build your global presence on web with webyfrog" /></SwiperSlide>
                <SwiperSlide><img className='slider_imgs' src={slider_two} alt="advertisement with webyfrog" /></SwiperSlide>
                <SwiperSlide><img className='slider_imgs' src={slider_img_three} alt="attract targete audience with facebook ads, with the help of webyfrog" /></SwiperSlide>
            </Swiper>
            )
        } else {
            return (
                <Swiper
                modules={[ Pagination, Autoplay, EffectCoverflow]}
                effect={'coverflow'}
                spaceBetween={15}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={2}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 150,
                    modifier: 1,
                    slideShadows: false,
                  }}
                pagination={{ clickable: true }}

            >
                <SwiperSlide><img className='slider_imgs' src={slider_img_one} alt="be number 1 on google search with webyfrog" /></SwiperSlide>
                <SwiperSlide><img className='slider_imgs' src={slider_img_foure} alt=".." /></SwiperSlide>
                <SwiperSlide><img className='slider_imgs' src={slider_two} alt="advertisement with webyfrog" /></SwiperSlide>
                <SwiperSlide><img className='slider_imgs' src={slider_img_three} alt="attract targete audience with facebook ads, with the help of webyfrog" /></SwiperSlide>
            </Swiper>
            )
        }
      }

    return (
        <>
            
        <SWiper/>
        </>
    )
}

export default Slider