import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';

function Upload_blogs() {

  const editor = useRef(null);
  const [content, setContent] = useState('');


  const [inputblog, setBlogs] = useState({ blog_thumbnail: "", img_alt: "", blog_title: "", blog_link: "", blog_discription: "" })
  let name, value;
  const heandleOnchange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setBlogs({ ...inputblog, [name]: value });
  }

  const actionOnclick = async (e) => {
    e.preventDefault();
    const blog_cont = content;
    const { blog_thumbnail, img_alt, blog_title, blog_link, blog_discription } = inputblog;
    try {
      const res = await fetch("/blog-raw-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          blog_cont, blog_thumbnail, img_alt, blog_title, blog_link, blog_discription
        })
      });
      const data = await res.json();
      if (res.status === 422 || !data) {
        window.alert("Contact Prabhat Papa")
      }
      else {
        window.alert("Successfully update, (Ab gan* dedo apni)")
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <form method='post'>
        <JoditEditor
          ref={editor}
          value={content}
          tabIndex={1}

          onChange={newCont => setContent(newCont)}
        />
        <div className="Upload_others">
          <input onChange={heandleOnchange} name='blog_thumbnail' type="text" placeholder='Blog Thumbnail' />
          <input onChange={heandleOnchange} name='img_alt' type="text" placeholder='Agar photo load nahi hui toh' />
          <input onChange={heandleOnchange} name='blog_title' type="text" placeholder='Blog Title' />
          <input onChange={heandleOnchange} name='blog_link' type="text" placeholder='Blog Link' />
          <input onChange={heandleOnchange} name='blog_discription' type="text" placeholder='Blog whatapp discription' />
        </div>
        <input type="submit" onClick={actionOnclick} />
      </form>
    </>
  )
}

export default Upload_blogs