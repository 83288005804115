import React, { useEffect } from 'react'
import Navbar from '../Nav_and_Footer/Navbar'
import Footer from '../Nav_and_Footer/Footer'
import aeropalne from "../../resources/aeroplane.svg"
import siddesh from "../../resources/founders/siddesh.svg"
import prabhat from "../../resources/founders/prabhat.svg"
import { Helmet } from "react-helmet";

function Aboutus() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])


    return (
        <>
            <Navbar />
            <div className="aboutus_all_cont">
                <Helmet>
                    <title>About Us</title>
                    <meta name="description" content="We are not just a digital marketing agency we're architects of the digital world" />
                </Helmet>
                <h1>ABOUT US <img src={aeropalne} alt="..." /></h1>
                <p className='aboutp1'>We're not just a digital marketing agency we’re architects of the digital world. With a passion for innovation and a flair for creativity, we offer a suite of services
                    that encompass web development, Android app creation, SEO, Google Ads, and Facebook
                    Ads. Our commitment to excellence is evident in every project we undertake, ensuring that
                    our clients not only meet but exceed their digital marketing goals. We pride ourselves on
                    delivering quality design and branding solutions that tell your story, engage your
                    audience, and build your brand...</p>
                <h3 className='abouth31'>Our mission is simple: empower businesses with cutting-edge solutions that not only
                    meet, but exceed their online goals...</h3>
                <h2 className='abouth21'>A Suite of Services to Launch You Forward:</h2>
                <ul>
                    <li><span>Website Development: </span>
                        We build clean, user friendly websites that convert visitors into
                        loyal customers.
                    </li>
                    <li><span>Android App Creation: </span>
                        Bring your ideas to life with engaging and functional Android
                        apps.
                    </li>
                    <li><span>SEO Mastery: </span>
                        Our SEO specialists ensure your brand reaches the right audience online.
                    </li>
                    <li><span>Targeted Advertising: </span>
                        We craft data driven Google Ads and Facebook Ads campaigns
                        that drive real results.
                    </li>
                    <li><span>Social Media Magic: </span>
                        Our Social Media Marketing experts curate engaging content and
                        strategies to build your brand voice and community.
                    </li>
                    <li><span>AI-Powered Efficiency: </span>
                        We leverage cutting edge AI to personalize your customer
                        experience and automate tasks, freeing you to focus on what matters most.
                    </li>
                    <li><span>Branding Brilliance: </span>
                        We don't just build websites and apps, we craft cohesive
                        branding experiences that tell your story, resonate with your audience, and build
                        lasting brand loyalty.
                    </li>
                </ul>
                <h2 className='abouth22'>We Offers More Than Just Services:</h2>
                <p className='aboutp1'>At Webyfrog, collaboration is key. We believe in building genuine partnerships with our
                    clients, fueled by a relentless pursuit of innovation. We're not satisfied until you achieve
                    (and surpass) your digital dreams.</p>

                <h2 className='abouth22'>Ready To Build Your Online Legacy?</h2>
                <h3 className='abouth31'>Contact Webyfrog today for a free consultation and see how we can transform your
                    digital presence.</h3>

                <div>
                    <a className='aboutl1' target='blank' href="https://forms.gle/143pAYrNaDLN14WKA">let’s talk</a>
                </div>

            </div>
            <div className="founders">
                <div className="founder_single_box">
                    <div className="founder_img">
                        <img src={siddesh} alt="webyfrog founder image" />
                    </div>
                    <div className="about_founder">
                        <h2 className='founderh2'>FOUNDER</h2>
                        <h2 className='foundr_name'>Siddhesh Sharma</h2>
                        <h2 className='founder_spe'>Digital Marketer | Product Manager</h2>
                        <p>I'm a passionate digital strategist with a keen eye for detail and a creative mind. I thrive on understanding the intricacies of online platforms and transforming complex data into actionable insights. My days are filled with exploring the digital landscape, seeking innovative solutions, and staying ahead of industry trends</p>
                        <p>
                            Helping businesses reach their full potential is my driving force. I believe that every business, regardless of size, has the potential to succeed online. My passion lies in crafting strategies that not only boost visibility but also drive tangible results. I'm committed to building strong partnerships with clients, understanding their goals, and delivering solutions that exceed expectations.
                        </p>
                        <p>With a strong foundation in research, SEO, design, and creative thinking, I can tackle any digital challenge. I excel at uncovering hidden opportunities, optimizing online presence, and creating visually appealing experiences. My ability to blend data-driven insights with creative flair sets me apart.</p>
                        <p>
                            I pride myself on transparency, reliability, and a results-oriented approach. My dedication to staying updated with the latest industry trends ensures that you're always ahead of the curve. I believe in building long-term relationships based on trust and mutual success. Let's work together to achieve your business goals.
                        </p>
                    </div>
                </div>
                <div className="founder_single_box">
                    <div className="founder_img">
                        <img src={prabhat} alt="webyfrog co-founder image" />
                    </div>
                    <div className="about_founder">
                        <h2 className='founderh2'>CO-FOUNDER</h2>
                        <h2 className='foundr_name'>Prabhat Patel</h2>
                        <h2 className='founder_spe'>Software Developer | Data Analyst</h2>
                        <p>I’m a dedicated software developer and cybersecurity expert with a knack for problem-solving and a passion for innovation.</p>
                        <p>
                            I excel at understanding the complexities of software systems and transforming intricate requirements into robust, secure solutions.
                        </p>
                        <p>
                            My days are spent navigating the ever-evolving tech landscape, seeking cutting-edge technologies, and staying ahead of security threats.
                        </p>
                        <p>
                            Ensuring businesses operate securely and efficiently is my primary goal. I believe that every organization, regardless of size, deserves top-notch security and seamless software performance.
                        </p>
                        <p>My passion lies in developing solutions that not only enhance functionality but also fortify defenses. I’m committed to building strong partnerships with clients, understanding their needs, and delivering solutions that surpass expectations.</p>
                        <p>
                            With a solid foundation in software development, cybersecurity, and innovative thinking, I can tackle any technical challenge. I excel at identifying vulnerabilities,optimizing system performance, and creating secure, user-friendly applications.My ability to merge technical expertise with creative problem-solving sets me apart.
                        </p>
                        <p>I pride myself on integrity, reliability, and a results-driven approach. My commitment to staying updated with the latest technological advancements ensures that you’re always protected and ahead of the curve.</p>
                        <p>Hhhhh</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Aboutus