import React from 'react'
import blackplane from "../../resources/blackplane.svg"
import Getstart from './Getstart'
import { Helmet } from "react-helmet";

function DandB() {
  return (
    <>
      <div className="activelink_all_cont">
        <Helmet>
          <title>Design And Branding</title>
          <meta name="description" content="Your brand is the heart and soul of your business. At WebyFrog, we specialize in crafting distinctive
          brand identities that resonate with your target audience and drive business growth" />
        </Helmet>
        <h1>Design and Brand Identity<img src={blackplane} alt="..." /></h1>
        <h3 className='activelinkh31'>WebyFrog: Crafting Your Brand's Unique Identity</h3>
        <p>Your brand is the heart and soul of your business. At WebyFrog, we specialize in crafting distinctive
          brand identities that resonate with your target audience and drive business growth. Our design
          and branding experts work closely with you to create a visual language that encapsulates your
          brand's essence and leaves a lasting impression.</p>
        <h2 className='activelinkh21'>Our Design and Branding Services.</h2>
        <div>
          <ul>
            <li><span>Brand Identity Development: </span>
              We delve deep into your business to uncover your brand's core
              values, personality, and target audience. Based on these insights, we create a comprehensive
              brand identity, including a compelling logo, color palette, typography, and visual style guide.
            </li>
            <li>
              <span>Logo Design: </span>
              Your logo is the face of your brand. Our designers create iconic logos that are
              memorable, versatile, and perfectly aligned with your brand's message.
            </li>
            <li>
              <span>Visual Identity Design: </span>
              We develop a cohesive visual language that extends beyond your logo,
              encompassing everything from business cards and stationery to marketing materials and digital
              assets.
            </li>
            <li>
              <span>Brand Guidelines: </span>
              To ensure consistency across all touchpoints, we create detailed brand
              guidelines outlining the proper usage of your brand elements.
            </li>
            <li>
              <span>Website Design and Development: </span>
              Your website is your digital storefront. We design and
              develop visually stunning websites that reflect your brand identity and provide a seamless user
              experience.
            </li>

          </ul>
        </div>
        <h3 className='activelinikh32'>How We Help You ?</h3>
        <div>
          <ul>
            <li>
              <span>Establish Brand Differentiation: </span>
              Create a unique brand identity that sets you apart from
              competitors.
            </li>
            <li> <span>A Build Brand Loyalty: </span>
              Develop a strong brand connection with your target audience.
            </li>
            <li> <span>Enhance Brand Recognition: </span>
              Create a memorable visual identity that leaves a lasting
              impressionj
            </li>
            <li>
              <span>Improve Brand Consistency: </span>
              Ensure your brand message is communicated consistently across
              all channels.
            </li>
          </ul>
        </div>
        <p>At WebyFrog, we believe that a strong brand is the foundation for long-term success. Our passion
          for design and branding combined with our strategic approach ensures that your brand stands
          out in the marketplace.</p>
      </div>
      <Getstart content="Contact us today to embark on a journey to build a powerful brand identity." link="https://forms.gle/143pAYrNaDLN14WKA" />
    </>
  )
}

export default DandB