import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';

function Upload_case() {

    const editor = useRef(null);
  const [content, setContent] = useState('');


  const [inputblog, setBlogs] = useState({ case_thumbnail: "", img_alt: "", case_title: "", case_link: "", case_discription: "" })
  let name, value;
  const heandleOnchange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setBlogs({ ...inputblog, [name]: value });
  }

  const actionOnclick = async (e) => {
    e.preventDefault();
    const case_cont = content;
    const { case_thumbnail, img_alt, case_title, case_link, case_discription } = inputblog;
    try {
      const res = await fetch("/casestd-raw-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          case_cont, case_thumbnail, img_alt, case_title, case_link, case_discription
        })
      });
      const data = await res.json();
      if (res.status === 422 || !data) {
        window.alert("Contact Prabhat Papa")
      }
      else {
        window.alert("Successfully update, (Ab gan* dedo apni)")
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <>
    <form method='post'>
        <JoditEditor
          ref={editor}
          value={content}
          onChange={newCont => setContent(newCont)}
        />
        <div className="Upload_others">
          <input onChange={heandleOnchange} name='case_thumbnail' type="text" placeholder='Blog Thumbnail' />
          <input onChange={heandleOnchange} name='img_alt' type="text" placeholder='Agar photo load nahi hui toh' />
          <input onChange={heandleOnchange} name='case_title' type="text" placeholder='Blog Title' />
          <input onChange={heandleOnchange} name='case_link' type="text" placeholder='Blog Link' />
          <input onChange={heandleOnchange} name='case_discription' type="text" placeholder='Blog whatapp discription' />
        </div>
        <input type="submit" onClick={actionOnclick} />
      </form>
    </>
  )
}

export default Upload_case