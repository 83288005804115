import React from 'react'
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import MediaQuery from 'react-responsive'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import slider1 from "../../resources/other/1.jpg"
import slider2 from "../../resources/other/2.jpg"
import slider3 from "../../resources/other/3.jpg"
import slider4 from "../../resources/other/4.jpg"
import slider5 from "../../resources/other/5.jpg"
import slider6 from "../../resources/other/6.jpg"
import slider7 from "../../resources/other/7.jpg"
import slider8 from "../../resources/other/8.jpg"
import slider9 from "../../resources/other/9.jpg"
import slider10 from "../../resources/other/10.jpg"
import slider11 from "../../resources/other/11.jpg"

function Our_work() {
    return (
        <>
            <div id='our_work_id' className="ourwork_all_cont">
                <h3 className='our_work_h3'>WebyFrog Clients</h3>
                <MediaQuery minWidth={600}>
                    <div className="all_slider_box">
                        <Swiper
                            modules={[Pagination, Autoplay]}
                            spaceBetween={35}
                            slidesPerView={4}
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            // pagination={{ clickable: true }}
                        >
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://kidfactory.in/"><img src={slider1} alt="webyfrog digital marketing agency client 1" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.belk.com/"><img src={slider2} alt="webyfrog digital marketing company client 2" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.macys.com/"><img src={slider3} alt="webyfrog digital marketing agency client 3" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://deorajewellery.shopdeck.com/"><img src={slider4} alt="webyfrog best digital marketing company client 4" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.fabletics.com/"><img src={slider5} alt="webyfrog best digital marketing agency client 5" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://niralihealthcare.org/"><img src={slider6} alt="webyfrog best digital marketing company client 6" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.narayanahealth.org/"><img src={slider7} alt="webyfrog best digital marketing agency client 7" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"> <a target='blank' href="https://www.mayoornoida.net/"><img src={slider8} alt="webyfrog best digital marketing agency client 8" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.sobha.com/"><img src={slider9} alt="webyfrog best digital marketing agency client 9" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"> <a target='blank' href="https://redscorpionsecurity.in/"><img src={slider10} alt="webyfrog best digital marketing agency client 10" /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><img src={slider11} alt="webyfrog best digital marketing company client 11" /></div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth={600}>
                    <div className="OW_M_Slider">
                        <div className="OW_M_left_slider">
                            <Swiper
                                modules={[Pagination, Autoplay]}
                                spaceBetween={19}
                                slidesPerView={3}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                            >
                                <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://kidfactory.in/"><img src={slider1} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.belk.com/"><img src={slider2} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.macys.com/"><img src={slider3} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://deorajewellery.shopdeck.com/"><img src={slider4} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.fabletics.com/"><img src={slider5} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://niralihealthcare.org/"><img src={slider6} alt=".." /></a></div>
                            </SwiperSlide>

                            </Swiper>
                        </div>
                        <div className="OW_M_Right_slider">

                            <Swiper
                                modules={[Pagination, Autoplay]}
                                spaceBetween={19}
                                slidesPerView={3}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                    reverseDirection: true
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                onSlideChange={() => console.log('slide change')}
                            >
                                <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.narayanahealth.org/"><img src={slider7} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"> <a target='blank' href="https://www.mayoornoida.net/"><img src={slider8} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><a target='blank' href="https://www.sobha.com/"><img src={slider9} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"> <a target='blank' href="https://redscorpionsecurity.in/"><img src={slider10} alt=".." /></a></div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ower_work_slider"><img src={slider11} alt=".." /></div>
                            </SwiperSlide>

                            </Swiper>

                        </div>
                    </div>
                </MediaQuery>

            </div>
        </>
    )
}

export default Our_work