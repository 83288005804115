import React from 'react'

function WhatsappAutomation() {
  return (
    <>
    <h1>hello this is whatsapp automation</h1>
    </>
  )
}

export default WhatsappAutomation